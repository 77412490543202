@import '../../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

  .promoBannerWrapper{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
    .bannerImage{
      width: 100%;
      aspect-ratio: auto;
      border-radius: 10px;
    }
  }
  .promoContentWrapper{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
    .promoItemWrapper{
      .questionSection{
        width: 100%;
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 20px;
        background-image: -webkit-linear-gradient( 90deg, rgb(239,244,255) 0%, rgb(255,255,255) 100%);
        box-shadow: 0px 8px 10px 0px rgba(29, 60, 142, 0.1),0px -8px 10px 0px rgba(220, 223, 231, 0.05);
        img{
          width: 100% !important;
          aspect-ratio: auto;
        }

        .promoItemHeader{
          text-align: center;
          margin: 10px 0px;
        }
        .accordionCard{
          padding: 0px 35px;
          width: 100%;
          max-height: 0;
          overflow: hidden;
          background-color: white;
          transition: max-height .5s;
          &.openAccordionCard {
              max-height: 1000px;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
          }
          .subItemSection{
            padding: 10px 0px;
            .subItemSectionHeader{
              padding: 6px 0px;
              font-weight: bold;
            }
            .subItemSectionContent{
              padding: 3px 0px;
              font-size: 16px;
            }
          }
        }

        .viewDetailBtn{
          cursor: pointer;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          border: 2px solid #FFFFFF;
          background-image: -webkit-linear-gradient( 90deg, rgb(152,229,240) 0%, rgb(137,214,232) 33%, rgb(122,198,223) 50%, rgb(137,204,232) 68%, rgb(152,210,240) 100%);
          box-shadow: 0px 8px 10px 0px rgba(29, 60, 142, 0.1);
          color: #FFFFFF
        }
        color: $textBlue;

        &>div:nth-child(1){
          width: 55%;
          display: flex;
          align-items: center;
        }
        &>div:nth-child(2){
          width: 45%;
          background-color: #EFF4FF;
        } 
      }
    }
    .fontColor{
      color: $textBlue;
    }
  }