.cardTitle{
  background-color: #deeafd;
  border: 1px solid white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 20px;
}

.cardBody{
  background: linear-gradient(to bottom, rgba(241,245,255,1), rgba(255, 255, 255, 1));
}

.cardImage{
  left: 140px;
  top: 22px;
  opacity: 0.5;
}

.serviceCardTwo{
  height: 190px;
}