@import "../../../scss/web_base.scss";

.bankCard{
  cursor: pointer;
  width: 220px;
  height: 120px;
  @include themify($themes){
    background-color: themed(bank-card-bg);
    border: 1px solid themed(bank-card-bg);
  }
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  .recyclePostion{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .removeCard{
    width: 20px;
    cursor: pointer;
  }
  .cardNo{
    color: $white;
    font-size: 24px;
  }
  .cardName{
    @include themify($themes){
      color: themed(bank-card-selected-border);
      }
    font-size: 18px;
  }
  &.selected {
    @include themify($themes){
      border: 4px solid themed(bank-card-selected-border);
    }
  }
}