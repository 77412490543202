@import '../../../../scss/web_base';

.pagination{
  span{
    cursor: pointer;
    padding: 3px 8px;
    margin: 0px 4px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 15px;
    @include themify($themes){
      color: themed(pagination-text-color);
      border: 1px solid themed(pagination-border-color);
    }
  }

  .paginationActive{     
    color: $white !important;
    @include themify($themes){
      border:1px solid themed(theme-primary-color);
      background-color: themed(theme-primary-color);
    }
  }
}