@import "../../../scss/web_base.scss";

.oddsTypeTable {
  @include themify($themes){
    border: 1px solid themed(table-border-color);
  }
  border-radius: 4px;
  table {
    width: 100%;
    tr {
      height: 40px;
      @include themify($themes){
        font-family: themed(font-family);
      }
      &:first-child {
        @include themify($themes){
          border-bottom: 1px solid themed(table-border-color);
        }
        .label {
          text-align: center;
          @include themify($themes){
            background: themed(table-cell-color);
          }
          width: 25%;
          &:first-child {
            border-top-left-radius: 3px;
          }
        }
        .value {
          text-align: center;
        }
      }
      &:last-child {
        .label {
          text-align: center;
          @include themify($themes){
            background: themed(table-cell-color);
          } 
          width: 25%;
          &:first-child {
            border-bottom-left-radius: 3px;
          }
        }
        .value {
          text-align: center;
          &.odds {
            @include themify($themes){
              color: themed(table-odds-color);
            }
          }
        }
      }
      .label {
        @include themify($themes){
          border-right: 1px solid themed(table-border-color);
        } 
      }
    }
  }
}