.serviceCardWrapper{
  background: linear-gradient(to bottom, rgba(224, 235, 253, 1), rgba(255, 255, 255, 1));
  border-width: 2px;
  border-color: white;
  .serviceImage{
    background-color: #e8f0fd;
    padding: 14px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .serviceSubtitle{
    left: 50px;
    top: 8px;
    font-size: 14px;
  }
}