@import '../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

%circle-button{
  padding: 2px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #8ba8e9;
  cursor: pointer;
  box-shadow: 0 0 10px 10px rgba(139, 168, 233, 0.5);
}

.homePageWebWrapper {
  width: 100%;
  background-image: url('/assets/image/default-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    @include themify($themes){
      background: themed(scrollbar-color);
    }
    border-radius: 5px;
  }

  .bannerWrapper{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
    .bannerImage{
      border-radius: 10px;
    }
  }
  .homeContentWrapper{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;

  }

}

.floatingTop{
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  background-color: #8ba8e9;
  color: #FFFFFF;
  cursor: pointer;
  box-shadow: 0 0 10px 10px rgba(139, 168, 233, 0.5);
}

.floatingContacts{
  bottom: 90px;
  right: 22px;
  @extend %circle-button;
}

.contactList{
  bottom: 130px;
  right: 22px;
}