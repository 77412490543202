.subMenuGradient{
  background: linear-gradient(to bottom, #DCE2FA, #FAFCFE) !important;
  width: 100%;
  z-index: 11 !important;
  position: absolute;
}

.subMenu{
  max-width: 1140px;
  margin: 0px auto;
  padding-left: 0;
  padding-right: 0;
  border: none;
  box-shadow: none;
}

.hovered{
  filter: brightness(120%);
}

.enterGame{
  position: absolute;
  z-index: 12;
  bottom: 45px;
  font-weight: bold;
  font-size: 18px;
  color: white;
  background: linear-gradient(to top, #2E7EFE, transparent);
  width: 158px;
  height: 50px; 
  padding: 20px 42px;
  cursor: pointer;
}