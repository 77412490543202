@import '../../../../scss/base';

.lianPengDanTuoWrapper{
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 36px;
  .oddsPart{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .oddsStyle{
      color: red;
    }
  }
  .LianPengDanTuoPart{
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px;
    .selected{
      @include themify($themes) {
        color: themed(theme-primary-color);
       }
    }
    .danTuoSelect{
      width: 60px;
      .el-input,input{
        height: 20px;
      }
    }
  }
}