@import '../../../scss/web_base';

.fantanTemplate {
  input {
    width: 70px;
    height: 25px;
    font-size: 12px;
    padding: 5px;
  }
  table {
    width: 100%;
    background-color: $white;
    text-align: center;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 4px;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    td,th{
      cursor: pointer;
      font-size: 20px;
      font-weight: normal;
      @include themify($themes) {
        color: themed( table-header-text-color);
        border: 1px solid themed(table-border-color);
      }
      border-top: none;
      border-left: none;
      height: 110px;
      width: 135px;
      .row {
        justify-content: center;
        margin: 0
      }
      .odds {
        font-size: 12px;
        @include themify($themes) {
          color: themed(table-odds-color);
        }
      }
      .label {
        font-weight: bold;
        font-size: 16px;
        @include themify($themes) {
          color: themed(fan-tan-label-text-color);
        }
      }
      .subLabel {
        font-size: 12px;
        @include themify($themes) {
          color: themed(fan-tan-label-text-color);
        }
      }
      &:hover {
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
      &.trapezoidWrapper {
        &:hover {
          cursor: unset;
          @include themify($themes){
            background-color: themed(table-border-color);
          }
        }
      }
      &.selected {
        @include themify($themes){
          background-color:themed(bet-items-label-selected-background-color) !important;
        }
      }
    }
    th{
      font-weight: normal !important;
    }
    tr {
      &:first-child {
        td {
          &:last-child,
          &:first-child {
            cursor: initial;
            @include themify($themes){
              background-color: themed(theme-third-color);
            }
          }
          &:nth-last-child(2),
          &:nth-child(2) {
            background-color: $fanTanCorner;
            @include themify($themes) {
              color: themed(fan-tan-corner-text-color);
            }
          }
        }
      }
      &:last-child {
        td {
          &:nth-last-child(2),
          &:nth-child(2) {
            background-color: $fanTanCorner;
            @include themify($themes) {
              color: themed(fan-tan-corner-text-color);
            }
          }
        }
      }
    }
  }
  .trapezoidWrapper {
    height: 350px;
    width: 350px;
    position: relative;
    @include themify($themes) {
      background: themed(table-border-color);
    }
    .middle {
      width: 93px;
      height: 173px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      background-color: $white;
      &.left {
        @include themify($themes) {
          border-right: 1px solid themed(table-border-color);
        }
        &>div:hover {
          cursor: pointer;
          @include themify($themes) {
            background-color: themed(theme-third-color);
          }
        }
      }
      &.right {
        &>div:hover {
          cursor: pointer;
          @include themify($themes) {
            background-color: themed(theme-third-color);
          }
        }
      }
      &>div.selected {
        @include themify($themes){
          background-color:themed(bet-items-label-selected-background-color) !important;
        }
      }
    }
    .trapezoid {
      &:hover {
        cursor: pointer;
        @include themify($themes) {
          background-color: themed(theme-third-color);
        }
      }
      &.selected {
        @include themify($themes){
          background-color:themed(bet-items-label-selected-background-color) !important;
        }
      }
      &.top {
        background: $fanTanTopBottom;
        width: 100%;
        display: flex;
        height: 25%;
        justify-content: center;
        -webkit-clip-path: polygon(0% 0%, 99.6% 0%, 74.8% 100%, 25% 100%);
        clip-path: polygon(0% 0%, 99.6% 0%, 74.8% 100%, 25% 100%);
        position: absolute;
        top: 0;
      }
      &.bottom {
        background: $fanTanTopBottom;
        width: 100%;
        display: flex;
        height: 25%;
        justify-content: center;
        -webkit-clip-path: polygon(0% 100%, 99.5% 100%, 74.7% 0%, 25% 0%);
        clip-path: polygon(0% 100%, 99.5% 100%, 74.7% 0%, 25% 0%);
        position: absolute;
        bottom: 0;
      }
      &.left {
        background: $fanTanLeftRight;
        width: 25%;
        display: flex;
        height: 100%;
        justify-content: center;
        -webkit-clip-path: polygon(0% 100%, 100% 74.9%, 100% 25.1%, 0% 0%);
        clip-path: polygon(0% 100%, 100% 74.9%, 100% 25.1%, 0% 0%);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &.right {
        background: $fanTanLeftRight;
        width: 25%;
        display: flex;
        height: 100%;
        justify-content: center;
        -webkit-clip-path: polygon(0% 74.8%, 100% 100%, 100% 0%, 0.5% 25.2%);
        clip-path: polygon(0% 74.8%, 100% 100%, 100% 0%, 0.5% 25.2%);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

