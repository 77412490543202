@import "../../../../scss/web_base.scss";

.tabListContainer{
  height: 100%;
  width: 100%;
  padding: 0.5rem 1rem;
  display: inline-block;
  &:hover{
    @include themify($themes){
      background-color: themed(theme-third-color) !important;
    }
  }
  
  &.tabAcitve{
    @include themify($themes){
      border-bottom: 3px solid themed(table-hk6-tab-border-color);
    }
  }
}