@import "../../../scss/web_base.scss";

.multipleItemsSelectionPanelWrapper{
	.headerTitle{
		@include themify($themes){
			background-color: themed(theme-third-color);
		}
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		@include themify($themes){
			border-top: 1px solid themed(table-border-color); 
			border-left: 1px solid themed(table-border-color); 
			border-right: 1px solid themed(table-border-color); 
		}
	}
	.leftSelectedWrapper{
		border-bottom-left-radius: 5px;
		@include themify($themes){
			border-top: 1px solid themed(table-border-color); 
			border-left: 1px solid themed(table-border-color); 
			border-bottom: 1px solid themed(table-border-color); 
		}
	}
  .templateStyle{
    border-collapse: collapse;
    border-bottom-right-radius: 5px;
    overflow: hidden;
		cursor: pointer;
    td {
			&:hover {
				@include themify($themes){
					background-color: themed(theme-third-color);
				}
			}
      @include themify($themes){
        border: 1px solid themed(table-border-color); 
      }
    }
		.disabledCell{
      @include themify($themes){
        background-color: themed(quick-bet-disabled) !important;
      }
    }
    .selectedCell{
      @include themify($themes){
        background-color: themed(quick-bet-selected) !important;
      }
    }
  }
  .circle{
		width: 25px;
		height: 25px;
		border-radius: 50%;
		display: inline-block;
		margin: 8px 0px;
		@include themify($themes){
				color: themed(table-ssc-circle-text-color);
				border: 4px solid themed(table-ssc-circle-border-color);
		}
		text-align: center;
		line-height: 17px;
		font-size: 10px;
		font-weight: bold;
		&.circleRed {
				border: 4px solid $ballRed !important;
		}
		&.circleBlue {
				border: 4px solid $ballBlue !important;
		}
		&.circleGreen {
				border: 4px solid $ballGreen !important;
		}
		&.circleYellow {
			border: 4px solid $ballYellow !important;
		}
	}
};