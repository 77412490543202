@import "../../../scss/web_base.scss";

.BetsItems{
  cursor: pointer;
  &:hover {
    @include themify($themes){
      background-color: themed(theme-third-color);
    }
  }
  &>div{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.selected {
    @include themify($themes){
        background-color:themed(bet-items-odds-selected-background-color) !important;
    }
  }
  .oddsLabel{
   width: 70%;
   @include themify($themes){
    border-bottom: 1px solid themed(table-border-color); 
    background-color: themed(theme-third-color) !important;
    }
  }
  .betsLabel{
    width: 30%;
    @include themify($themes){
      border-left: 1px solid themed(table-border-color); 
      border-bottom: 1px solid themed(table-border-color); 
      background-color: themed(theme-third-color) !important;
    }
  }
  .betsTotal{
    width: 30%;
    @include themify($themes){
			border-left: 1px solid themed(table-border-color); 
		}
  }
  .inputAmount{
    width: 70%;
    .chooseBetInput{
      max-width: 56px;
      width: 90%;
      height: 20px;
      margin: 0 auto;
      outline: none;
      border-radius: 2px;
      padding: 2px;
      font-size: 12px;
      @include themify($themes){
          border: 1px solid themed(table-border-color);
      }
    }
  }
}