@import '../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

  .homeFooterWrapper{
    width: 100%;
    background-color: #182452;
    padding: 0px 70px;
  }

  .homeFooter{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
  }

  .aboutUs{
    border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);
    color: white
  }