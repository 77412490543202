@import "../../../scss/web_base.scss";

.probabilitySelectionWrapper {
  flex-wrap: nowrap;
  @include themify($themes){
    font-family: themed(font-family);
    border-bottom: 1px solid themed(probability-border-color);
  }
  .optionListWrapper {
    .arrowWrapper {
      width: 7%;
      .arrow {
        @include themify($themes){
          border: 1px solid themed(probability-border-color);
        }
        height: 30px;
        width: 30px;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
      }
    }
    .optionItemListWrapper {
      overflow: auto;
      width: 80%;
      white-space: nowrap;
      margin: 0px 10px;
      padding-top: 3px;
      &::-webkit-scrollbar {
        display: none;
      }
      .optionItemWrapper {
        width: 95px;
        display: inline-block;
        cursor: pointer;
        .row {
          align-items: center;
        }
        .optionWrapper {
          @include themify($themes){
            border: 1px solid themed(probability-border-color);
          }
          width: 20px;
          height: 20px;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          display: flex;
          margin-right: 5px;
          margin-left: 5px;
          margin-top: 2px;
          .active {
            @include themify($themes){
              fill: themed(theme-primary-color);
            }
            svg {
              path {
                @include themify($themes){
                  fill: themed(theme-primary-color);
                }
              }
            }
          }
          .inactive {
            @include themify($themes){
              fill: $white;
            }
          }
          &.active {
            @include themify($themes){
              border: 1px solid themed(theme-primary-color);
            }
          }
        }
        &.active {
          @include themify($themes){
            color: themed(theme-primary-color);
          }
        }
      }
    }
  }
  .rightFilter {
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0;
    .modeWrapper {
      cursor: pointer;
      & > span.optionWrapper {
        margin-right: 0.5em;
        position: relative;
        display: flex;
        width: 1.6em;
        height: 1.6em;
        border-radius: 3px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        @include themify($themes){
          border: 1px solid themed(leng-re-option-border-color);
        }
      }
      .el-popover {
        @include themify($themes) {
          color: themed(leng-re-popover-content);
        }
        .lengReHot {
          padding: 2px 5px;
          border-radius: 5px;
          margin-left: 5px;
          font-size: 11px;
          @include themify($themes) {
            border: 1px solid themed(leng-re-value-hot-background-color) !important;
            color: themed(leng-re-value-hot-background-color);
          }
        }
        .lengReCold {
          padding: 2px 5px;
          border-radius: 5px;
          margin-left: 5px;
          font-size: 11px;
          @include themify($themes) {
            border: 1px solid themed(leng-re-value-cold-background-color) !important;
            color: themed(leng-re-value-cold-background-color);
          }
        }
        .yiLouHot {
          padding: 2px 5px;
          border-radius: 5px;
          margin-left: 5px;
          font-size: 11px;
          color: $white;
          @include themify($themes) {
            background-color: themed(leng-re-value-hot-background-color);
          }
        }
        .yiLouCold {
          padding: 2px 5px;
          border-radius: 5px;
          margin-left: 5px;
          color: $white;
          font-size: 11px;
          @include themify($themes) {
            background-color: themed(leng-re-value-cold-background-color);
          }
        }
      }
    }
    .quickBetBtn {
      font-size: 0.9em;
      padding: 0.5em;
      @include themify($themes){
        background-color: themed(quickbet-button-color);
        border-color: themed(quickbet-button-color);
      }
    }
    .optionWrapper {
      @include themify($themes){
        border: 1px solid themed(probability-border-color);
      }
      width: 20px;
      height: 20px;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 2px;
      .active {
        @include themify($themes){
          fill: themed(leng-re-active-text-color);
        }
      }
      .inactive {
        @include themify($themes){
          fill: $white;
        }
      }
      &.active {
        @include themify($themes){
          border: 1px solid themed(leng-re-active-text-color);
        }
      }
    }
    .active {
      @include themify($themes){
        color: themed(leng-re-active-text-color);
      }
    }
    .qiContainer {
      font-size: 0.9em;
      @include themify($themes){
        color: themed(leng-re-inactive-text-color);
      }
      .active {
        @include themify($themes){
          color: themed(leng-re-active-text-color);
        }
      }
      .col-md-4 {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        @include themify($themes){
          border-right: 1px solid themed(probability-border-color);
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
    .quickBetBtn{
      width: 76px;
      height: 30px;
      background-color: #ff9c1c;
      line-height: 14px;
      text-align: center;
      font-weight: bold;
      color: $white;
      display: inline-block;
      white-space: nowrap;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .popOverWrapper {
      justify-content: center;
      align-items: center;
      display: flex;
      > span {
        width: auto !important;
        height: auto !important;
        border: none !important;
        display: flex;
      }
    }
  }
}