@import '../../../../scss/web_base';

.resultTable{
  height: 1px;
  @include themify($themes) {
    font-family: themed(font-family) !important;
  }
  margin: 8px 10px;
  background-color: $white;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  td,th{
    @include themify($themes){
      border: 1px solid themed(table-border-color);
    }
    padding: 0px;
  }
  th{
    font-size: 13px;
    font-weight: normal;
  }
  .resultTableHeader{
    @include themify($themes){
      background-color: themed(theme-third-color);
      color:themed(result-table-header-text);
    }
    font-size: 13px;
    font-weight: normal;
    padding: 8px 4px;
    &>th:nth-of-type(1){
      width: 80px !important;
    }
    &>th:nth-of-type(2){
      width: 100px !important;
    }
  }
  .resultBodyRow{
    @include themify($themes){
      color:themed(result-table-row-text);
    }
    cursor: pointer;
   &:hover{ @include themify($themes){
      background-color: themed(theme-third-color);
      }
    }
  }
}