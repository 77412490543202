@import '../../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

  .bannerWrapper{
    margin-top: 10px;
    width: 100%;
    .carouselStyle{
      height: calc(100vh - 110px) !important;
      .el-carousel__container{
        height: calc(100vh - 110px) !important;
      }
    .bannerImage{
      width: 100%;
      height: calc(100vh - 110px);
    }
    .el-carousel__button{
      border-radius:2px;
      height: 18px !important;
      width: 18px !important;
      border: 3px solid #9BAFE1;
      background-color: transparent;
      margin-left: 10px;
      margin-right: 10px;
    }
    .is-active{
      .el-carousel__button{
        background-color: #1955F1 !important;
        border: 3px solid #1955F1 !important;
      }
    }
  }
  }
  .homeContentWrapper{
    padding: 0px 40px;

    .noticeWrapper{
      font-size: 12px;
      color: $textBlue;
      margin-top: 40px;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      background-image: -webkit-linear-gradient( 90deg, rgb(239,244,255) 0%, rgb(255,255,255) 100%);
      box-shadow: 0px 8px 10px 0px rgba(29, 60, 142, 0.1),0px -8px 10px 0px rgba(220, 223, 231, 0.05);

      .btnMore{
        white-space: nowrap;
        cursor: pointer;
        padding: 5px 25px;
        color: #6F97FF;
        font-size: 22px;
        border-radius: 5px;
        border: 1px solid #6F97FF;
      }

      .noticeTitle{
        background: linear-gradient(to bottom, #b9ceff, #FAFCFE) !important;
        width: 100px;
        font-weight: bold;
        border-top-right-radius: 20px;
      }
    }

    .topGameWrapper{
      margin-top: 25px;
      .fontColor{
        color: $textBlue;
      }
    }

    .appCardWrapper{
      background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
      border-width: 0px 0px 1px;

      .qrImg{
        width: 150px;
        height: 170px;
        margin: 0px 15px;
      }

      .qrPosition{
        top: 36px;
        left: 52px;
      }

      .appDownload{
        background: linear-gradient(to top, #EAEDF4 0%,#000000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 0.05;
        top: -22px;
        left: 30px;
        width: 100%;
        font-size: 60px;
      }
    }
  }