@import "../../../../scss//web_base.scss";

.quickBet {
    @include themify($themes){
        background-color: themed(theme-third-color);
    }
    align-items: center;
    margin: 15px 0px;
    .quickSelectionAmountBtn{
        width: 82px;
        height: 28px;
        @include themify($themes){
        border: 1px solid themed(theme-primary-color);
        color: themed(theme-primary-color);
        }
        border-radius: 4px;
        background-color: #fff;
        overflow: hidden;
        margin-right: 4px;
        line-height: 25px;
        font-size: 14px;
    }
    .confirmBtn{
        width: 84px;
        height: 28px;
        @include themify($themes){
            background-color: themed(theme-primary-color);
        }
        color: $white;
        border-radius: 5px;
        border: 0;
        outline: none;
        font-size: 12px;
        font-weight: bold;
        margin-left: 15px;
    }
    .resetBtn{
        width: 84px;
        height: 28px;
        @include themify($themes){
            background-color: themed(reset-button-color);
        }
        color: $white;
        border-radius: 5px;
        border: 0;
        outline: none;
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
    }
    .presetAmount{
        label {
            @include themify($themes) {
                color: themed(quick-bet-label-color);
            }
        }
        .form-check-input {
         margin-top: 0;   
        }
        .quickBetAmountInput{
            @include themify($themes){
                border:1px solid themed(quick-bet-border-color);
            }
            width: 84px;
            height: 20px;
            border-radius: 4px;
            padding-left: 4px;
            box-sizing: border-box;
            font-size: 12px;
            outline: none;
            background-color: $white !important;
        }
    }
    .quickBetAmountBtnGroup{
        input{
            @include themify($themes){
                border:1px solid themed(quick-bet-border-color);
                color: themed(quick-bet-input-text-color);
            }
            width: 35px;
            height: 28px;
            text-align: center;
            background-color: $white !important;
            border-radius: 4px;
            margin: 0 2px;
            padding: 0;
            font-size: 12px;
            outline: none;
            cursor: pointer;
            &:hover {
                @include themify($themes){
                    border:1px solid themed(theme-primary-color);
                    color: themed(theme-primary-color);
                }
            }
            &.active {
                @include themify($themes){
                    background-color: themed(theme-primary-color);
                    color: $white;
                }
            }
        }
    }
}