$blue: #dde3fa;

.userProfileWrapper{
  width: 180px;
  z-index: 12 !important;
  top: 15px;
  right: -50px;
  position: absolute;
  border-radius: 10px;
}

.backgroundBlue{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $blue;
}

.backgroundWhite{
  background-color: white;
  padding: 12px 22px;
}

.backgroundDarkBlue{
  background-color: darken($blue, 20%);
  color: white;
}