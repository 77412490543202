@import '../../../scss/base';

.optionWrapper{
  margin: 1px;
  position: relative;
  background-color: $white;
  &.selected{
    @include themify($themes){
      border: 1px solid themed(theme-primary-color) !important;
    }
  }
  &.disabled{
    background-color: #D8D8D8 !important;
  }
  .itemPaused{
    background-color: #D8D8D8 !important;
  }

  .selectedIcon{
    position: absolute;
    height: 18px;
    width: 18px; 
    padding-left: 4px;
    right: 0px;
    bottom: 0px;
    border-radius: 18px 0px 0px 0px;
    @include themify($themes){
      background-color: themed(theme-primary-color);
    }
  }
  .selectedIconDan{
    color: white;
    position: absolute;
    text-align: center;
    height: 18px;
    width: 18px; 
    right: 0px;
    top: 0px;
    @include themify($themes){
      background-color: themed(theme-primary-color);
    }
  }
  &.PK10ItemWrapper{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 49%;
    max-width: 50%;
    &.PK10ItemWrapperGY{
      flex-direction: column;
    }
    @include themify($themes) {
      border: 1px solid themed(border-color);
    }
    padding: 5px 3px;
    .oddsStyle{
      color: $red;
    }

    .PK10_ball {
      color: $white;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      display: block;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
      &.square_1 {
        @include themify($themes){
            border-color: themed(table-square-1) !important;
            background-color: themed(table-square-1);
        }
      }
      &.square_2 {
          @include themify($themes){
              border-color: themed(table-square-2) !important;
              background-color: themed(table-square-2);
          }
      }
      &.square_3 {
          @include themify($themes){
              border-color: themed(table-square-3) !important;
              background-color: themed(table-square-3);
          }
      }
      &.square_4 {
          @include themify($themes){
              border-color: themed(table-square-4) !important;
              background-color: themed(table-square-4);
          }
      }
      &.square_5 {
          @include themify($themes){
              border-color: themed(table-square-5) !important;
              background-color: themed(table-square-5);
          }
      }
      &.square_6 {
          @include themify($themes){
              border-color: themed(table-square-6) !important;
              background-color: themed(table-square-6);
          }
      }
      &.square_7 {
          @include themify($themes){
              border-color: themed(table-square-7) !important;
              background-color: themed(table-square-7);
          }
      }
      &.square_8 {
          @include themify($themes){
              border-color: themed(table-square-8) !important;
              background-color: themed(table-square-8);
          }
      }
      &.square_9 {
          @include themify($themes){
              border-color: themed(table-square-9) !important;
              background-color: themed(table-square-9);
          }
      }
      &.square_10 {
          @include themify($themes){
              border-color: themed(table-square-10) !important;
              background-color: themed(table-square-10);
          }
      }
    }
  }

  &.CJ_11X5ItemWrapper{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 49%;
    max-width: 50%;

    @include themify($themes) {
      border: 1px solid themed(border-color);
    }
    padding: 5px 3px;
    .oddsStyle{
      color: $red;
    }

    .circle {
      margin: 2px !important;
      color: #626262;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: block;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      font-weight: bold;
      background-color: $white;
      @include themify($themes){
        border: 4px solid $ballBlue;
      }
    }
  }

  &.Hk6ItemWrapper{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 49%;
    max-width: 50%;
    &.threeColumnStyle{
      flex-direction: column;
      width: 33.3% !important;
      min-width: 32.3% !important;
      max-width: 33.3% !important;
    }
    &.HK6TeXiao{
      flex-direction: column;
      .HK6TeXiaoWrapper{
        padding: 0px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .circle{
          margin: 2px;
        }
      }
    }
    &.HK6SeBo{
      flex-wrap: wrap;
      min-width: 99%;
      max-width: 100%;
      &>div:first-child{
        width: 50%;
        text-align: end;
        padding-right: 5px;
      }
      .oddsStyle{
        width: 50%;
        text-align:start;
        padding-left: 5px;
      }
      .HK6SeBoWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .circle{
          margin: 2px;
        }
      }
    }
    @include themify($themes) {
      border: 1px solid themed(border-color);
    }
    padding: 5px 3px;
    .oddsStyle{
      color: $red;
    }
    .circle{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: block;
      text-align: center;
      line-height: 17px;
      font-size: 10px;
      font-weight: bold;
      &.circleRed {
          border: 4px solid $ballRed !important;
      }
      &.circleBlue {
          border: 4px solid $ballBlue !important;
      }
      &.circleGreen {
          border: 4px solid $ballGreen !important;
      }
      &.circleYellow {
        border: 4px solid $ballYellow !important;
      }
    }
  }

  &.BJK8ItemWrapper{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 49%;
    max-width: 50%;
    flex-direction: column;
    @include themify($themes) {
      border: 1px solid themed(border-color);
    }
    padding: 5px 3px;
    .oddsStyle{
      color: $red;
    }
    .ball{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 auto;
      text-align: center;
      line-height: 10px;
      font-size: 10px;
      font-weight: bold;
      &.ballBlueLight{
        width: 25px;
        height: 25px;
        line-height: 17px;
        @include themify($themes){
            background-color: themed(BJK8-ball-light);
            border: 4px solid themed(BJK8-ball-light) !important;
            color: themed(BJK8-ball-text-color) !important;
        }
      }
      &.ballBlueDark{
        width: 25px;
        height: 25px;
        line-height: 17px;
        @include themify($themes){
            background-color: themed(BJK8-ball-dark);
            border: 4px solid themed(BJK8-ball-dark) !important;
        }
        color: $white !important;
      }
    }
  }
}