@import '../../../scss/_web_base';

.timerWrapperHorizontal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  @include themify($themes){
    font-family: themed(font-family);
  }
  .bellWrapper {
    justify-content: center;
    border: 1px solid #00000030;
    position: relative;
    width: 25px;
    height: 25px;
    text-align: center !important;
    border-radius: 50%;
    line-height: 23px;
    img {
      width: 15px;
      height: 15px;
    }
    &.bellOn {
      @include themify($themes){
        background-color: themed(theme-primary-color);
      }
    }
    &.bellOff {
      @include themify($themes){
        background-color: themed(inactive-bell-background-color);
      }
    }
  }
}

.timerWrapperVertical {
  color: $white;
  font-size: 15px;
  @include themify($themes){
    font-family: themed(font-family);
  }
  .colonText {
    font-size: 20px;
    display: inline-block;
  }
  .timerText {
    background-color: $white;
    padding: 0 2px;
    color: #448eed;
    font-weight: bold;
    font-size: 22px;
    border-radius: 2px;
    margin-left: 2.5px;
    margin-right: 2.5px;
    display: inline-block;
    @include themify($themes){
      color: themed(theme-primary-color);
    }
  }
  .installmentWrapper {
    display: inline-block;
    width: max-content;
    vertical-align: middle;
    padding: 0px 2px;
  }
  .bellWrapper {
    justify-content: center;
    border: 1px solid #00000030;
    position: relative;
    width: 25px;
    height: 25px;
    text-align: center !important;
    border-radius: 50%;
    line-height: 23px;
    img {
      width: 15px;
      height: 15px;
    }
    &.bellOn {
      @include themify($themes){
        background-color: themed(theme-primary-color);
      }
    }
    &.bellOff {
      @include themify($themes){
        background-color: themed(inactive-bell-background-color);
      }
    }
  }
}