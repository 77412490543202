@import '../../../../scss/base';

.FantLiangMianWrapper{
  width: 100%;
  position: relative;
  padding: 5px;
  .FantLiangMianTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .FantTanContent{
    width: 100%;
    &>div:nth-child(odd){
      height: 70px;
      &>div:nth-child(even){
        background-color: turquoise;
      }
      &>div:nth-child(odd){
        background-color: lightgrey;
      }
    }
    &>div:nth-child(even){
      height: 150px;
      &>div:nth-child(odd){
        background-color: darkgoldenrod;
      }
    }
    &>div{
      display: flex;
      margin-bottom: 10px;
      &>div:nth-child(even){
        width: 50%;
        &:not(.centerContent){
          border: 1px solid black;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        margin: 0px 5px;
        &.centerContent{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &>div{
            width: 48%;
            border: 1px solid black;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightgrey;
          }
          &>div:nth-child(1),&>div:nth-child(2){
            margin-bottom: 5px;
          }
        }
      }
      &>div:nth-child(odd){
        border: 1px solid black;
        width: 25%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  .betItem{
    .itemOdds{
      color: red;
    }
    &.selected{
      background-color: yellow !important;
    }
  }
}