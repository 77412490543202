@import '../../../scss/web_base';


.loginAndRegister{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 13px;
  .loginNoAccount{
      display: flex;
      align-items: center;
      margin-top: 20px;
      span{
          @include themify($themes){
              color: themed(theme-primary-color);
          }
          cursor: pointer;
      }
      .registerButton{ 
        margin-left: auto;
        width: 148px;
        height: 39px;
        span{
          color: white !important;
        }
        @include themify($themes){
            background-color: themed(theme-primary-color);
          }
        &:hover{
          @include themify($themes){
              background-color: themed(theme-secondary-color) !important;
          }
        }
        text-align: center;
        border-radius: 69px;
        padding: 3px 15px;
        border: none !important;
        font-weight: bold;
        cursor: pointer;
      }
      .trialButton{ 
        margin-left: auto;
        width: 148px;
        height: 39px;
        @include themify($themes){
          border: 2px solid themed(theme-primary-color);
        }
        &:hover{
            @include themify($themes){
                background-color: themed(theme-secondary-color) !important;
            }
            span{
              color: white !important;
            }
        }
        background-color: transparent;
        text-align: center;
        border-radius: 69px;
        padding: 3px 15px;
        cursor: pointer;
      }
  }
  .loginBox{
      width: 450px;
      background-color: white;
      padding: 15px 45px;
      border-radius: 20px;
      @media (max-height: 600px){
        .formInputs{
          max-height: 250px;
          overflow-y: auto;
        }
      }
      @media (min-height: 600px) and (max-height: 770px){
        .formInputs{
          max-height: 320px;
          overflow-y: auto;
        }
      }
      .loginTitle{
        img{
          width: 172px;
          margin-bottom: 10px;
        }
          @include themify($themes){
              color: themed(theme-secondary-color);
          }
          font-weight: bold;
          font-size: 12px;
          margin-bottom: 20px;
      }
      .borderRed{
        border-color: #dc3545 !important;
      }
      .loginBoxItem{
          margin-bottom: 24px;
          height: 39px;
          border-radius: 69px;
          width: 100%;
          border: 2px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 13px;
          Input{
            font-size: 13px;
          }
          .formFeedback {
            position: absolute;
            top: 100%;
            left: 24px;
            width: auto;
            font-size: 10px;
          }
          .registerSMSBtn{
            border-radius: 69px;
            width: 100px;
            height: 28px;
            margin-top: 5px;
            margin-right: 4px;
            margin-bottom: 5px;
            font-size: 12px;
            @include themify($themes){
              background-color: themed(theme-primary-color);
            }
            &:hover{
              @include themify($themes){
                  background-color: themed(theme-secondary-color) !important;
              }
            }
            border: none !important;
          }
          .loginButton{
              @include themify($themes){
                  background-color: themed(theme-primary-color);
              }
              &:hover{
                  @include themify($themes){
                      background-color: themed(theme-secondary-color) !important;
                  }
              }
              height: 39px !important;
              width: 100%;
              border-radius: 69px;
              border: none !important;
              font-weight: bold;
          }
          &>div:first-child{
              font-weight: bold;
              margin-right: -14px;
              @include themify($themes){
                  color: themed(theme-primary-color);
              }
              width: 30%;
              padding: 18px;
          }
          input{
              flex: 1;
              border: none !important;
              background-color: transparent !important;
              &:focus{
                  box-shadow: none !important;
              }
          }
          #captchaImg{
              height: 40px !important;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
          }
      }
      .closeIcon {
        cursor: pointer;
        @include themify($themes){
          color: themed(theme-primary-color);
        }
        position:absolute;
        right: 5px;
        top: 5px;
      }
  }

}

.successModalWrapperWeb {
  width: 500px !important;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .successTick {
      height: 7em;
      width: 7em;
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    @include themify($themes) {
      border-top: 1px solid themed(success-modal-footer-border-color)
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
.successModalWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 100%;
  }
  .modalHeader{
    line-height: 45px;
    width: 100%;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    width: 100%;
    .row{
      width: 100%;
    }
    .successTick{
      height: 80px;
    }
  }
  .modalFooter{
    width: 100%;
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
}