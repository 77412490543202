@import '../../../scss/base';

.datepickerWrapper {
  width: 300px;
  .modal-header {
    justify-content: center;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
    }
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    h5 {
      font-size: 1.2em;
      font-weight: bold;
    }
  }
  .datepickerModal {
    padding: 0;
    .datepicker {
      position: relative;
      font-size: 0.75em;
      background-color: $white;
      &.ios {
        .datepicker-viewport {
          &::after {
            background: none;
          }
        }
      }
      .datepicker-content {
        padding: 1em;
        .datepicker-wheel {
          @include themify($themes) {
            border-top: 1px solid themed('theme-primary-color');
            border-bottom: 1px solid themed('theme-primary-color');
          }
        }
      }
    }
  }
  .modal-footer {
    justify-content: center;
    border-top: none;
    .row {
      width: 100%;
      .col-6 {
        padding-left: 0.5em;
        padding-right: 0.5em;
        .button {
          border: none;
          @include themify($themes) {
            background-color: themed('theme-primary-color');
          }
        }
      }
    }
  }
}