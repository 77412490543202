@import "../../../../scss//web_base.scss";

.chooseBet{
    cursor: pointer;
    height: max-content;
    .probabilityExist {
        margin-right: 21px !important;
    }
    .probabilityWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        right: 0;
        .lengreValueWrapper {
            font-size: 10px;
            color: $white;
            width: 18px;
            border-top-left-radius: 5px;
            @include themify($themes) {
                border-left: 1px solid themed(leng-re-value-normal-background-color);
                border-top: 1px solid themed(leng-re-value-normal-background-color);
                background: themed(leng-re-value-normal-background-color);
            }
            &.hot {
                @include themify($themes) {
                    border-left: 1px solid themed(leng-re-value-hot-background-color);
                border-top: 1px solid themed(leng-re-value-hot-background-color);
                    background: themed(leng-re-value-hot-background-color);
                }
            }
            &.cold {
                @include themify($themes) {
                    border-left: 1px solid themed(leng-re-value-cold-background-color);
                    border-top: 1px solid themed(leng-re-value-cold-background-color);
                    background: themed(leng-re-value-cold-background-color);
                }
            }
        }
        .yilouValueWrapper {
            font-size: 10px;
            width: 18px;
            border-bottom-left-radius: 5px;
            @include themify($themes) {
                border-top: 1px solid themed(yi-lou-value-normal-text-color);
                border-left: 1px solid themed(yi-lou-value-normal-text-color);
                border-bottom: 1px solid themed(yi-lou-value-normal-text-color);
                color: themed(yi-lou-value-normal-text-color);
            }
            &.hot {
                @include themify($themes) {
                    border-top: 1px solid themed(yi-lou-value-hot-text-color);
                    border-left: 1px solid themed(yi-lou-value-hot-text-color);
                    border-bottom: 1px solid themed(yi-lou-value-hot-text-color);
                    color: themed(yi-lou-value-hot-text-color);
                }
            }
            &.cold {
                @include themify($themes) {
                    border-top: 1px solid themed(yi-lou-value-cold-text-color);
                    border-left: 1px solid themed(yi-lou-value-cold-text-color);
                    border-bottom: 1px solid themed(yi-lou-value-cold-text-color);
                    color: themed(yi-lou-value-cold-text-color);
                }
            }
        }
    }
    .oddsInput {
        width: 62px;
    }
    &>div{
        height: 40px;
        align-self: stretch;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &>div:not(:last-child){
        @include themify($themes){
            border-right: 1px solid themed(table-border-color);
        }
    }
    &>div:nth-of-type(1){
			@include themify($themes){
					background-color:themed(table-cell-color) !important;
			}
			&.FJ_LMPL_Cell{
					background: none !important;
      }
			&.hk6CellBackgroundColor{
					@include themify($themes){
							background-color: themed(hk6-choose-bet-cell) !important;
					}
			}
    }
    .column-3items-label {
			height: 40px;
			padding: 0;
			position: relative;
			&.selected {
					@include themify($themes){
							background-color:themed(bet-items-label-selected-background-color) !important;
					}
			}
			&.hk6-hx-label {
					width: 25% !important;
			}
			&.hk6-hx-checkbox {
					width: 20% !important;
			}
			.label {
				@include themify($themes){
					color: themed(choose-bet-label);
				}
			}
			.hk6-sb-red-text {
					@include themify($themes){
							color: themed(result-red-text);
					}
			}
			.hk6-sb-blue-text {
					@include themify($themes){
							color: themed(result-blue-text);
					}
			}
			.hk6-sb-green-text {
					@include themify($themes){
							color: themed(result-green-text);
					}
			}
    }

		.column-2items-input,
    .column-2items-odds {
			&.selected {
					@include themify($themes){
							background-color:themed(bet-items-odds-selected-background-color) !important;
					}
			}
    }

    .column-3items-input,
    .column-3items-odds {
			&.selected {
					@include themify($themes){
							background-color:themed(bet-items-odds-selected-background-color) !important;
					}
			}
    }
    .column-3items-label-and-image {
			height: 70px;
			width: 120px;
    }
    .column-3items-array-balls {
			display: flex !important;
			padding: 0px !important;
			&>div:not(:last-child){
				@include themify($themes){
						border-right: 1px solid themed(table-border-color);
				}
			}
			&>div{
				flex: 1;
				text-align: center;
				padding: 0.5rem 0 !important;
			}
    }
    .chooseBetOdds{
			@include themify($themes){
					color:themed(table-odds-color);
			}
    }
    .square_1 {
			@include themify($themes){
					border-color: themed(table-square-1) !important;
					background-color: themed(table-square-1);
			}
    }
    .square_2 {
			@include themify($themes){
					border-color: themed(table-square-2) !important;
					background-color: themed(table-square-2);
			}
    }
    .square_3 {
			@include themify($themes){
					border-color: themed(table-square-3) !important;
					background-color: themed(table-square-3);
		}
	}
	.square_4 {
		@include themify($themes){
				border-color: themed(table-square-4) !important;
				background-color: themed(table-square-4);
		}
	}
	.square_5 {
		@include themify($themes){
				border-color: themed(table-square-5) !important;
				background-color: themed(table-square-5);
		}
	}
	.square_6 {
		@include themify($themes){
				border-color: themed(table-square-6) !important;
				background-color: themed(table-square-6);
		}
	}
	.square_7 {
		@include themify($themes){
				border-color: themed(table-square-7) !important;
				background-color: themed(table-square-7);
		}
	}
	.square_8 {
		@include themify($themes){
				border-color: themed(table-square-8) !important;
				background-color: themed(table-square-8);
		}
	}
	.square_9 {
		@include themify($themes){
				border-color: themed(table-square-9) !important;
				background-color: themed(table-square-9);
		}
	}
	.square_10 {
		@include themify($themes){
				border-color: themed(table-square-10) !important;
				background-color: themed(table-square-10);
		}
	}
	
	.square {
		width: 28px;
		height: 28px;
		display: block;
		margin: 0 auto;
		text-align: center;
		color: $white !important;
		font-size: 14px;
		font-weight: bold;
		border: 3px solid;
		border-radius: 5px;
	}
	.circle{
		width: 25px;
		height: 25px;
		border-radius: 50%;
		display: block;
		margin: 8px 0px;
		@include themify($themes){
				color: themed(table-ssc-circle-text-color);
				border: 4px solid themed(table-ssc-circle-border-color);
		}
		text-align: center;
		line-height: 17px;
		font-size: 10px;
		font-weight: bold;
		&.circleRed {
				border: 4px solid $ballRed !important;
		}
		&.circleBlue {
				border: 4px solid $ballBlue !important;
		}
		&.circleGreen {
				border: 4px solid $ballGreen !important;
		}
		&.circleYellow {
			border: 4px solid $ballYellow !important;
	}
	}

	.ball{
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin: 0 auto;
		text-align: center;
		line-height: 10px;
		font-size: 10px;
		font-weight: bold;
		&.ballBlueLight{
			width: 25px;
			height: 25px;
			line-height: 17px;
			@include themify($themes){
					background-color: themed(BJK8-ball-light);
					border: 4px solid themed(BJK8-ball-light) !important;
					color: themed(BJK8-ball-text-color) !important;
			}
		}
		&.ballBlueDark{
			width: 25px;
			height: 25px;
			line-height: 17px;
			@include themify($themes){
					background-color: themed(BJK8-ball-dark);
					border: 4px solid themed(BJK8-ball-dark) !important;
			}
			color: $white !important;
		}
	}
	
	.fillRed {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include themify($themes){
					font-family: themed(font-family);
			}
			margin: 0px;
			background-color: $ballRed;
			color: $white !important;
			font-size: 12px;
	}
	.fillBlue {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include themify($themes){
					font-family: themed(font-family);
			}
			margin: 0;
			background-color: $ballBlue;
			color: $white !important;
			font-size: 12px;
	}
	.fillGreen {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include themify($themes){
					font-family: themed(font-family);
			}
			margin: 0;
			background-color: $ballGreen;
			color: $white !important;
			font-size: 12px;
	}
	.chooseBetInput{
			max-width: 60px;
			width: 90%;
			height: 20px;
			margin: 0 auto;
			outline: none;
			border-radius: 2px;
			padding: 2px;
			font-size: 12px;
			@include themify($themes){
					border: 1px solid themed(table-border-color);
			}
	}
	.chooseBetCheckbox{
			width: 14px;
			height: 14px;
			margin: 0 auto;
			outline: none;
			border-radius: 2px;
			padding: 2px;
			font-size: 12px;
			@include themify($themes){
					border: 1px solid themed(table-border-color);
			}
	}
	.showDan {
		left: 10px !important;
	}
	.danText {
			font-size: 15px;
			position: relative;
			left: 10px;
	}
}

