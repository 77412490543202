@import "../../../../scss/web_base";

.betTypeContainerWrapper {
  display: flex;
  align-items: center;
  margin: 0;
  > div {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .optionWrapper {
    @include themify($themes){
      border: 1px solid themed(probability-border-color);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 2px;
    .active {
      height: 8px;
      margin: 0;
      @include themify($themes){
        fill: themed(theme-primary-color);
      }
      svg {
        path {
          @include themify($themes){
            fill: themed(theme-primary-color);
          }
        }
      }
    }
    .inactive {
      @include themify($themes){
        fill: $white;
      }
    }
    &.active {
      @include themify($themes){
        border: 1px solid themed(theme-primary-color);
      }
    }
    &:first-child {
      margin-left: 15px;
    }
  }
  .el-select {
    position: relative;
    .el-select-dropdown {
      z-index: 2;
      min-width: 100px !important;
      will-change: top, left !important;
      top: -120px !important;
      bottom: 20px !important;
      .el-scrollbar {
        overflow-y: hidden;
        position: relative;
        height: 115px !important;
      }
      .el-scrollbar__bar {
        &.is-vertical {
          display: none;
        }
      }
      .el-scrollbar__wrap {
        width: 100%;
        overflow: scroll!important;
        height: 110px;
        &::-webkit-scrollbar {
          width: 2px;
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #448eed;
        }
      }
    }
    input{
      height: auto;
      width: 100px;
    }
  }
  .dtText{
    @include themify($themes){
      color:themed(table-odds-color);
    }
  }
  .drawer-container{
    .containerDrawerOpend{
      display: flex;
      max-width: 100%;
      transition: max-width 0.3s ease 0s;
      overflow: hidden;
    }
    .containerDrawerClosed{
      display: flex;
      max-width: 0px;
      transition: max-width 0.3s ease 0s;
      overflow: hidden;
    }
    .optionSelected{
      @include themify($themes){
        background-color: themed(theme-primary-color) !important;
        border: 1px solid themed(theme-primary-color) !important;
        color: $white !important;
      }
    }
    .squareBtn{
      height: 18px;
      line-height: 16px;
    }

  }
}