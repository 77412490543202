@import '../../scss/_web_base';

.betPanelWrapper {
  .betPanelHeader {
    height: 34px;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes){
      background-color: themed(theme-primary-color);
    }
    color: $white;
    font-size: 0.9em;

    .subGameWrapper {
      display: flex;
      align-items: center;
      border-top-left-radius: 0.3em;
      font-size: 13px;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
    .timerWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 13px;
      .bellWrapper {
        justify-content: center;
        border: 1px solid #00000030;
        position: relative;
        width: 25px;
        height: 25px;
        text-align: center !important;
        border-radius: 50%;
        line-height: 23px;
        img {
          width: 15px;
          height: 15px;
        }
        &.bellOn {
          @include themify($themes){
            background-color: themed(theme-primary-color);
          }
        }
        &.bellOff {
          @include themify($themes){
            background-color: themed(inactive-bell-background-color);
          }
        }
      }
    }
    .winningWrapper {
      display: flex;
      justify-content: center;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    .installmentWrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      .bellIcon {
        font-size: 1.2em;
        cursor: pointer;
      }
    }
  }

  .betPanelContent {
    &.HK6WaterMark,
    &.MCHK6WaterMark,
    &.TWHK6WaterMark {
      input {
        background-color: transparent;
      }
    }

    &.HK6WaterMark{
      background-image: url(../../assets/image/games/watermark/watermark_hk6.png);
    }
    &.MCHK6WaterMark{
      background-image: url(../../assets/image/games/watermark/watermark_mchk6.png);
    }
    &.TWHK6WaterMark{
      background-image: url(../../assets/image/games/watermark/watermark_twhk6.png);
    }
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &.isHorizontal {
      border-radius: 3px;
    }
  }
}