@import '../../../../scss/base';

.TemaAndLianMaWrapper{
  width: 100%;
  position: relative;
  padding: 0px 15px;
  .scrollRow{
    white-space: nowrap;
    overflow-x: scroll;
    padding: 10px 0px;
    .TeMaAndLianMaBtn{
      border-radius: 3px;
      padding: 3px 5px;
      margin: 0px 5px;
      background-color: white;
      @include themify($themes) {
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
       }
      &.selected{
        @include themify($themes) {
          background-color: themed(theme-primary-color) !important;
         }
         color: white !important;
      }
    }
  }
}