@import '../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

.homePageWebWrapper {
  background-color: #FBFCFF !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    @include themify($themes){
      background: themed(scrollbar-color);
    }
    border-radius: 5px;
  }
  .homeHeader{
    padding: 15px 0px;
    display: flex;
    align-items: center;
    width: 100%;
    color: $textBlue;
    .logoText{
      width: 220px;
      color: $deepBlue;
      font-size: 40px;
    }
  }

  .bannerWrapper{
    margin-top: 10px;
    width: 100%;
    .carouselStyle{
      height: calc(100vh - 110px) !important;
      .el-carousel__container{
        height: calc(100vh - 110px) !important;
      }
    .bannerImage{
      width: 100%;
      height: calc(100vh - 110px);
    }
    .el-carousel__button{
      border-radius:2px;
      height: 18px !important;
      width: 18px !important;
      border: 3px solid #9BAFE1;
      background-color: transparent;
      margin-left: 10px;
      margin-right: 10px;
    }
    .is-active{
      .el-carousel__button{
        background-color: #1955F1 !important;
        border: 3px solid #1955F1 !important;
      }
    }
  }
  }
  .homeContentWrapper{
    padding: 0px 40px;

    .contentHeader{
      margin-top: 40px;
      display: flex;
      width: 100%;
      .topTitle{
        font-size: 40px;
        text-align: center;
        font-weight: bolder;
        color: $deepBlue;
        text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
      }
      .subTitle{
        font-size: 25px;
        text-align: center;
        color: $shallowBlue;
      }
      .lineAndDot{
        flex: 1;
        display: flex;
        align-items: center;
        .circleDot{
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 3px solid $shallowBlue;
        }
        .line{
          flex: 1;
          border: 1px solid $shallowBlue;
        }
      }
    }

  }
}