@import "../../../scss/web_base.scss";

.doubleDatePicker{
  .dateSelector{
    cursor: pointer;
    height: 29px !important;
    line-height: 27px;
    @include themify($themes){
      border: 1px solid themed(result-border);
    }
  }
  #rangeButton4{
    display: none !important;
  }
  .timeContainer{
    display: none !important;
  }
}

.singleDatePicker{
  .dateSelector{
    cursor: pointer;
    height: 29px !important;
    line-height: 27px;
    @include themify($themes){
      border: 1px solid themed(result-border);
    }
  }
  #rangeButton4{
    display: none !important;
  }
  .disabled{
    @include themify($themes){
      background-color: themed(safety-centre-button-disabled);
    }
  }
  .timeContainer{
    display: none !important;
  }
  .rangecontainer{
    display: none;
  }
  .fromDateTimeContainer:nth-of-type(2){
    display: none;
  }
}