@import "../../../scss/web_base.scss";

.shortCutInputWrapper {
  height: 62px;
  width: 100%;
  @include themify($themes){
    font-family: themed(font-family);
    background-color: themed(shortcut-input-background-color);
  }
  .title {
    @include themify($themes){
      font-family: themed(font-family);
      background-color: themed(shortcut-input-background-color);
      color: themed(shortcut-title-text-color);
      border: 1px dotted themed(shortcut-title-wrapper-color);
    }
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 29px;
    padding: 0px 20px;
  }
  .form-control {
    &::placeholder {
      font-size: 12px;
    }
  }
  .resetBtn{
    width: 84px;
    height: 28px;
    @include themify($themes){
        background-color: themed(reset-button-color);
    }
    color: $white;
    border-radius: 5px;
    border: 0;
    outline: none;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}
}