@import '../../../scss/web_base';

.latestAnnouncementWrapperWeb {
  width: 500px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  div {
    margin: 0;
  }
  .modalHeader {
    line-height: 36px;
    text-align: center;
    color: $white;
    font-size: 15px;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    justify-content: center;
  }
  .modalContent {
    font-size: 13px;
    width: 100%;
    max-height: 400px;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .contentWrapper {
      width: 100%;
      word-break: break-all;
      max-height: 105px;
      overflow-y: scroll;
      .title {
        @include themify($themes) {
          color: themed(theme-primary-color);
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        @include themify($themes){
          background: themed(scrollbar-color);
        }
        border-radius: 5px;
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      border-top: 1px solid themed(modal-footer-border-color);
    }
    .doNotRemindTitle {
      cursor: pointer;
    }
    .acknowledgeButton {
      width: 120px;
      height: 36px;
      background: $white;
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
        color: themed(theme-primary-color);
      }
    }
    .detailButton {
      border: 0;
      width: 120px;
      height: 36px;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }
    }
  }
}