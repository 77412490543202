.cardWrapper{
  cursor: pointer;
}
.card {
  position: relative;
  border-radius: 15px !important;
}

.card-img-top{
  border-radius: 15px !important;
}

.card-img-character{
  position: absolute;
}

.card-content{
  z-index: 10;
  position: absolute;
  padding: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2E7EFE;
  opacity: 0.8;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.card-text{
  font-weight: bold;
}