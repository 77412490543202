@import '../../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

.CollaborateBannerWrapper{
  max-width: 1140px;
  margin: 0px auto;
  padding-left: 0;
  padding-right: 0;
  .bannerImage{
    width: 100%;
    aspect-ratio: auto;
    border-radius: 10px;
  }
}
  .CollaborateContentWrapper{
    max-width: 1140px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;

    .topGameWrapper{
      margin-top: 25px;
      .el-tabs, .el-tabs--border-card{
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
      }

      .el-tabs__header{
        background-color: transparent !important;
        border: none !important;
      }
      .el-tabs__nav-scroll{
        display: flex;
        justify-content: center;
      }

      .el-tabs__nav{
        padding: 10px 0px !important;
      }
      
      .el-tabs__item{
        padding: 0px 50px;
        margin: 0px 30px;
        border-radius: 35px;
        border: 2px solid #FFFFFF;
        color: #143487;
        box-shadow: 0px 8px 10px 0px rgba(29, 60, 142, 0.1);
        background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(245,248,252) 33%, rgb(235,240,249) 50%, rgb(245,248,252) 68%, rgb(255,255,255) 100%);
        &.is-active{
          color: #FFFFFF;
          background-image: -webkit-linear-gradient( 90deg, rgb(152,180,240) 0%, rgb(137,167,232) 33%, rgb(122,154,223) 50%, rgb(137,167,232) 68%, rgb(152,180,240) 100%);
        }
      }
      .el-tab-pane{
        padding: 40px;
        font-size: 20px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        box-shadow: -4px -6.928px 10px 0px rgba(211, 225, 255, 0.1), 4px 6.928px 10px 0px rgba(29, 60, 142, 0.1);
        color: #143487;
        .mainTitleStyle{
          margin-bottom: 20px;
          font-size: 30px;
          font-weight: bold;
        }
        .secondTitleStyle{
          font-weight: bold;
          margin: 20px 0px;
        }
        img{
          cursor: pointer;
          margin-right: 20px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  h6 {
    line-height: 1.5; 
    padding-top: 4px;
    padding-bottom: 4px;
  }