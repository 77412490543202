@import "../../../scss/web_base.scss";

.multipleTypeBetsPanelWrapper{
	@include themify($themes){
		border:  1px solid themed(table-border-color);
	}
	&>div:not(:last-child){
		@include themify($themes){
			border-right: 1px solid themed(table-border-color); 
		}
	}
	&>div:not(:last-child){
	flex: 1;
	}
	&>div:last-child{
		width: 60px !important;
		&>div:first-child{
			@include themify($themes){
				border-bottom: 1px solid themed(table-border-color); 
				background-color: themed(theme-third-color);
			}
		}
		&>div{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	border-radius: 5px; 
	.headerTitle{
		@include themify($themes){
			background-color: themed(theme-third-color);
		}
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		@include themify($themes){
			border-top: 1px solid themed(table-border-color); 
			border-left: 1px solid themed(table-border-color); 
			border-right: 1px solid themed(table-border-color); 
		}
	}
	.leftSelectedWrapper{
		border-bottom-left-radius: 5px;
		@include themify($themes){
			border-top: 1px solid themed(table-border-color); 
			border-left: 1px solid themed(table-border-color); 
			border-bottom: 1px solid themed(table-border-color); 
		}
	}
  .templateStyle{
    border-collapse: collapse;
    border-bottom-right-radius: 5px;
    overflow: hidden;
		cursor: pointer;
    td {
      @include themify($themes){
        border: 1px solid themed(table-border-color); 
      }
    }
		.disabledCell{
      @include themify($themes){
        background-color: themed(quick-bet-disabled);
      }
    }
    .selectedCell{
      @include themify($themes){
        background-color: themed(quick-bet-selected);
      }
    }
  }
  .circle{
		width: 25px;
		height: 25px;
		border-radius: 50%;
		display: inline-block;
		margin: 8px 0px;
		@include themify($themes){
				color: themed(table-ssc-circle-text-color);
				border: 4px solid themed(table-ssc-circle-border-color);
		}
		text-align: center;
		line-height: 17px;
		font-size: 10px;
		font-weight: bold;
		&.circleRed {
				border: 4px solid $ballRed !important;
		}
		&.circleBlue {
				border: 4px solid $ballBlue !important;
		}
		&.circleGreen {
				border: 4px solid $ballGreen !important;
		}
		&.circleYellow {
			border: 4px solid $ballYellow !important;
	}
	}
};