$textBlue:#143487;

.accordionWrapper{
  color: $textBlue;
  font-size: 20px;

  .accordionQuestion{
    background-color: #deeafd;
    border: 1px solid white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
  }
  
  .accordionAnswer{
    background: linear-gradient(to bottom, rgba(241,245,255,1), rgba(255, 255, 255, 1));
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
