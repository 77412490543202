@import '../../../scss/web_base';

.tabContainer {
  .nav-item {
    cursor: pointer;
    & a {
      color: $black;

      &:hover {
        border-color: $white;
        border: 0;
        border-radius: 0;
        @include themify($themes){
          background: themed(tab-header-hover-background-color);
        }
      }
    }
    .indicator {
      height: 2px;
      &.active {
        @include themify($themes){
          background: themed(theme-primary-color);
        }
      }
      &.inactive {
        @include themify($themes){
          background: $white;
        }
      }
    }
  }
  .changLongHeaderPanel{
    min-width: 100% !important;
    font-size: 16px;
    cursor: default;
    margin: 0px !important;
    border: none !important;
    border-radius: 0px !important;
    & a:hover {
      border-color: $white;
      border: 0;
      border-radius: 0;
      @include themify($themes){
        background: themed(theme-primary-color);
      }
    }
  }
  .changlongSelectionTabs{
    margin-top: 5px;
    margin-bottom: 5px;
    @include themify($themes){
      border-bottom: 1px solid themed(customise-changlong-modal-template-code-color);
      border-top: 1px solid themed(customise-changlong-modal-template-code-color);
    }
  }
}