@import '../../../scss/web_base';

.textInputModalWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 279px;
  }
  .modalHeader{
    font-size: 17px;
    line-height: 45px;
    width: 279px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    font-size: 15px;
  }
  .modalFooter{
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed('theme-primary-color');
        border: 1px solid themed('theme-primary-color');
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        color: themed('theme-primary-color');
        border: 1px solid themed('theme-primary-color');
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}