@import '../../../../scss/web_base';

$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;
$textBlue:#143487;

.headerGradient{
  background: linear-gradient(to bottom, #DCE2FA, #FAFCFE) !important;
  width: 100%;
}

.divider{
  opacity: 0.5;
}

.homeHeader{
  z-index: 11 !important;
  top: 0px !important;
  color: $textBlue;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  border: none;
  box-shadow: none;
  .logoWrapper{
    height: 50px;
  }
  .selected{
    color: $deepBlue !important;
  }
  .serviceItem,.gameItem{
    cursor: pointer;
    margin: 0px 2px;
    img{
      width: 100%;
      display: none;
    }
  }
  .gameWrapper{
    font-size: 16px;
    font-weight: bold;
  }
  .serviceWrapper{
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    opacity: 0.8;
  }
  .homeloginAndRegister{
    font-size: 14px;
    cursor: pointer;
    height: 40px;
    font-weight: bold;
  }
  .userIcon{
    cursor: pointer;
    position: relative;
  }
  .reloadIcon{
    background-color: #f0f2fc;
    cursor: pointer;
    border-radius: 5px;
  }
  .cursorPointer{
    cursor: pointer;
  }
  .notificationBadge{
    top:-2px;
    right: -15px;
  }
}
