@import '../../scss/base';  

.modal{
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 1050;
  outline: 0;
  height: 100%;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden !important;
  text-align: -webkit-center !important;
}
.fade {
  transition: opacity .4s ease-out;
}
@keyframes popUpShow {
  0% {
    opacity: 0.3 !important;
    -webkit-transform: translateY(150px);
    -moz-transform: translateY(150px);
    -ms-transform: translateY(150px);
    -o-transform: translateY(150px);
    transform: translateY(150px);
  }
  60% {
    opacity: 0.9 !important;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1 !important;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes popUpHide {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  60% {
    opacity: 0.9;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
  }
}
.popUpOpen {
  animation: popUpShow 900ms ease-out 1 forwards;
}
.popUpClose{
  animation: popUpHide 400ms ease-out 1 forwards;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  max-width: 550px !important;
  &.mobile {
    margin: 0 !important;
    &.error,
    &.success,
    &.confirmation {
      .modal-content {
        width: 280px;
      }
    }
  }
  &.error,
  &.success,
  &.confirmation {
    .modal-content {
      width: auto;
    }
  }
  &.notification {
    .modal-content {
      width: 400px;
    }
  }
  &.datepicker {
    .modal-content {
      width: 300px;
    }
  }
  .modal-content {
    width: max-content;
    border: none;
  }
  &.radioButtonList {
    .modal-content {
      width: 280px;
      max-height: 500px;
      overflow-y: hidden;
    }
  }
  &.topUp {
    .modal-content{
      width: 80%;
    }
  }
}