@import "../../../scss/web_base.scss";

.multiTypePanelWrapper{
  padding: 0;
  border-radius: 5px;
  @include themify($themes){
    border: 1px solid themed(table-border-color) !important;
  }
  .checkBoxRow{
    &.F36X7{
      &>div:nth-of-type(1){
        width: 25% !important;
        flex: none !important;
      }
      &>div:nth-of-type(2){
        width: 75% !important;
        flex: none !important;
      }
    }
    &>div:nth-of-type(1) {
      @include themify($themes){
        background-color:themed(table-cell-color) !important;
      }
      &:first-child{
        border-top-left-radius: 4px;
      }
      &:last-child{
        border-top-right-radius: 4px;
      }
    }
    display: flex;
    &>div{
      flex: 1;
      padding: 0.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>div:not(:last-child){
      @include themify($themes){
      border-right: 1px solid themed(table-border-color);
      }
    }
    .disabledCell {
      @include themify($themes) {
        background: themed(checkbox-disabled-background-color);
      }
    }
  }

  .tabRow{
    display: flex;
    background-color: $white;
    padding: 0px 3px;
  }
  .betTypeContainerRow{
    display: flex;
    &>div:first-child{
      width: 83px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        border-right: 1px solid themed(table-border-color);
        background-color:themed(table-cell-color) !important;
      }
    }
  }
  .oddsRow{
    display: flex;
    &.F36X7{
      &>div:nth-of-type(1){
        width: 25% !important;
        flex: none !important;
      }
      &>div:nth-of-type(2){
        width: 75% !important;
        flex: none !important;
      }
    }
    &.F36X7_3Z2{
      &>div:nth-of-type(1){
        width: 25% !important;
        flex: none !important;
      }
      &>div:nth-of-type(2){
        width: 75% !important;
        flex-direction: row !important;
      }
    }
    &>div:first-child{
      @include themify($themes){
          background-color:themed(table-cell-color) !important;
      }
    }
    &>div{
      flex: 1;
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    .displayText{
      @include themify($themes){
        color:themed(multiple-type-odds-tips) ;
      }
    }
    &>div:not(:last-child){
      @include themify($themes){
      border-right: 1px solid themed(table-border-color);
      }
    }
    .chooseBetOdds{
      @include themify($themes){
          color:themed(table-odds-color);
      }
    }
  }
  .multiTypePanel{
    table-layout: fixed;
    cursor: pointer;
    border-collapse: collapse;
    overflow: hidden;
    @include themify($themes) {
      font-family: themed(font-family) !important;
    }

    td {
      @include themify($themes){
        border: 1px solid themed(table-border-color); 
      }
    }

    tr {
      td {
        padding: 0;
        &:first-child {
          border-left: 0;
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-right: 0;
        }
      }
      &:first-child {
        td {
          border-top: 0;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    .cellWrapper {
      &:hover {
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
        &.disabledCell {
          @include themify($themes) {
            background: themed(checkbox-disabled-background-color);
          }
        }
      }
      .optionWrapper {
        @include themify($themes){
          border: 1px solid themed(probability-border-color);
        }
        width: 16px;
        height: 16px;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 10px;
        .active {
          height: 8px;
          margin: 0;
          @include themify($themes){
            fill: themed(theme-primary-color);
          }
          svg {
            path {
              @include themify($themes){
                fill: themed(theme-primary-color);
              }
            }
          }
        }
        .inactive {
          @include themify($themes){
            fill: $white;
          }
        }
        &.active {
          @include themify($themes){
            border: 1px solid themed(theme-primary-color);
          }
        }
      }
    }
  }
}