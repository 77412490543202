@import '../../../scss/web_base';

.template2 {
    padding: 0;
    border-radius: 5px;
    @include themify($themes){
      border: 1px solid themed(table-border-color); 
    }
    .keyCodeCategoryWrapper {
      padding-left: 0;
      @include themify($themes){
        border-bottom: 1px solid themed(table-border-color);
      }
      .categoryLabel {
        font-size: 12px;
        width: 50px;
        display: inline-block;
        padding-bottom: 10px;
        text-align: center;
        cursor: pointer;
        &.active {
          @include themify($themes){
            border-bottom: 4px solid themed(theme-primary-color);
          }
        }
      }
    }

    .templateStyle{
      border-collapse: collapse;
      border-radius: 5px;
      overflow: hidden;
      .FJ_LMPL{
        @include themify($themes){
					background-color:themed(table-cell-color) !important;
			  }
      }
      td {
        @include themify($themes){
          border: 1px solid themed(table-border-color); 
        }
      }

      thead {
        tr {
          td {
            &:first-child {
              border-left: 0;
            }
          }
          td {
            &:last-child {
              border-right: 0;
            }
          }
          &:first-child {
            td {
              border-top: 0;
            }
          }
        }
      }
  
      tbody {
        tr {
          td {
            padding: 0;
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          &:first-child {
            td {
              border-top: 0;
            }
          }
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
      
      .header-bg{
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
      .table-title{
        &>div:not(:last-child){
          @include themify($themes){
            border-right: 1px solid themed(table-border-color);
          }
        }
        .column-2items-label {
          width: 100%;
        }
      }
      .table-cell {
        .chooseBet {
          .column-2items-label {
            width: 100%;
          }
        }
        &:hover {
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        }
        &.emptyContainer {
          &:hover {
            background: none;
          }
          .column-3items-label {
            height: 40px;
            padding: 0;
            @include themify($themes) {
              border-right: 1px solid themed(table-border-color);
              background-color:themed(table-cell-color) !important;
            }
          }
          .column-3items-odds {
            @include themify($themes) {
              border-right: 1px solid themed(table-border-color);
            }
          }
          .column-empty-label {
            width: 100%;
            height: 40px;
            align-self: stretch;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include themify($themes) {
              border-right: 1px solid themed(table-border-color);
              background-color:themed(table-cell-color) !important;
            }
          }
          .column-empty-odds,
          .column-empty-input {
            width: 100%;
            height: 40px;
            align-self: stretch;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .column-3items-empty-label {
            width: 40%;
            height: 40px;
            align-self: stretch;
            display: flex;
            justify-content: center;
            align-items: center;
            @include themify($themes) {
              border-right: 1px solid themed(table-border-color);
              background-color:themed(table-cell-color) !important;
            }
          }
          .column-3items-empty-odds {
            width: 30%;
            height: 40px;
            align-self: stretch;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include themify($themes) {
              border-right: 1px solid themed(table-border-color);
            }
          }
          .column-3items-empty-input {
            width: 30%;
            height: 40px;
            align-self: stretch;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .column-3items-empty-checkbox{
            width: 30% !important;
            &.hk6-hx-checkbox {
              width: 15% !important;
            }
          }
        }
      }

      .column-1item-style{
        width: 100% !important;
      }
      .column-3items-label{
        width: 40% !important;
        &.hk6-hx-label {
          width: 25% !important;
        }
        &.paddingLabel{
          padding: 0px !important;
        }
        .hk6-sb-red-bg {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $white;
          @include themify($themes){
              background-color: themed(result-red-text);
            }
        }
        .hk6-sb-blue-bg {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: $white;
            @include themify($themes){
                background-color: themed(result-blue-text);
            }
        }
        .hk6-sb-green-bg {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: $white;
            @include themify($themes){
                background-color: themed(result-green-text);
            }
        }
      }
      .column-2items-odds{
        width: 70% !important;
      }
      .column-2items-input{
        width: 30% !important;
      }
      .column-3items-odds{
        width: 30% !important;
      }
      .column-3items-input{
        width: 30% !important;
        &.hk6-hx-ball {
          width: 60% !important;
        }
      }
      .column-3items-checkbox{
        width: 30% !important;
        position: relative;
        &.hk6-hx-checkbox {
          width: 15% !important;
        }
      }
      .column-3items-style-4th{
        width: 35% !important;
        flex-direction: column;
      }
      .column-3items-style-5th{
        width: 35% !important;
        padding: 0;
      }
      .column-4items-label{
        width: 10% !important;
        &.paddingLabel{
          padding: 0px !important;
        }
        .hk6-sb-red-bg {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $white;
          @include themify($themes){
              background-color: themed(result-red-text);
            }
        }
        .hk6-sb-blue-bg {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: $white;
            @include themify($themes){
                background-color: themed(result-blue-text);
            }
        }
        .hk6-sb-green-bg {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: $white;
            @include themify($themes){
                background-color: themed(result-green-text);
            }
        }
      }
      .column-4items-odds{
        width: 10% !important;
        &.selected {
          @include themify($themes){
              background-color:themed(bet-items-odds-selected-background-color) !important;
          }
        }
      }
      .column-4items-input{
        width: 20% !important;
        &.selected {
          @include themify($themes){
              background-color:themed(bet-items-odds-selected-background-color) !important;
          }
        }
      }
      .column-4items-checkbox{
        width: 20% !important;
        &.selected {
          @include themify($themes){
              background-color:themed(bet-items-odds-selected-background-color) !important;
          }
        }
      }
      .column-4items-balls{
        width: 60% !important;
        &.selected {
          @include themify($themes){
              background-color:themed(bet-items-odds-selected-background-color) !important;
          }
        }
      }
      .column-4items-array-balls {
        display: flex !important;
        padding: 0px !important;
        &>div{
            text-align: center;
            padding: 0 !important;
            margin: 0 1px;
        }
    }   
      th {
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
      }

      td{
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
        padding:0px;
      }
    }
  }