@import "../../../scss/web_base.scss";

.outWrapper{
  border-radius: 3px;
  border:1px solid #dcdcdc;
  padding: 12px;
  font-size: 12px;
  background-color: white;
  box-shadow: 0px 10px 10px 0px rgba($black, 0.3);
  .checkAllBtn{
      margin-left: auto;
      cursor: pointer;
      padding: 7px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      border-radius: 5px;
      color: white;
      @include themify($themes){
          background-color: themed(theme-primary-color) !important;
      }
  }


  .lotteryMenuWrapper {
  display: flex;
  position: relative;

  .menuSection{
      margin: 0px 13px;
      width: 106px;
      .title{
          color: #666666;

      }
      .gapLine{
          width: 100%;
          margin-bottom: 5px;
          margin-top: 2px;
          border-bottom: 1px solid #cccccc;
      }
      .content{
          .lotteryName{
              white-space: nowrap;
              padding: 5px 0px;
              cursor: pointer;
              color: #838383;
              &:hover, &.selected{
                  font-weight: bold !important;
                  @include themify($themes){
                      color: themed(theme-primary-color) !important;
                  }

              }
          }
      }

      
  }
  }
}