
$deepBlue:#2D63DB;
$shallowBlue:#9BAFE1;

.divider-title{
  font-size: 40px;
  text-align: center;
  font-weight: bolder;
  color: $deepBlue;
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
}
.divider-icon{
  background-color: $shallowBlue;
}
.divider-dot{
  width: 6px;
  height: 6px;
  background-color: #f9bd4b;
}
.divider-dot-long{
  width: 20px;
  height: 6px;
  background: linear-gradient(to right, #f9bd4b, transparent);
}
.text-grey{
  color: #c4cddf;
}