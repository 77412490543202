
%circle-button{
  padding: 2px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #8ba8e9;
  cursor: pointer;
  box-shadow: 0 0 10px 10px rgba(139, 168, 233, 0.5);
}

.contactCircleButton{
  @extend %circle-button;
}

.customLink{
  color: #8ba8e9;
}