@import '../../../../scss/base';

.collapseTypeWrapper{
  .collapseTypeHeader{
    height: 40px;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-right: 1px solid themed(border-color);
      border-left: 1px solid themed(border-color);
      border-bottom: 1px solid themed(border-color);
    }
  }
  .collapseTypeContent{
    display: flex;
    flex-wrap: wrap;
    // background-color: $white;
    &.collapseTypeContentOpend{
      max-height: 1000px;
      transition: max-height 2.5s ease-out;
      overflow-y: hidden;
    }
    &.collapseTypeContentClosed{
      max-height: 0px;
      transition: max-height 1.5s ease-out;
      overflow-y: hidden;
    }
  } 
}