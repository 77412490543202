@import '../../../scss/web_base';

.customiseChanglongModalWrapperWeb {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 900px;
  }
  .col,.row {
    margin: 0;
  }
  .modalHeader {
    line-height: 36px;
    text-align: center;
    color: $white;
    font-size: 15px;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    justify-content: center;
    }
  .modalContent {
    font-size: 14px;
    height: max-content;
    .bottomLine{
      border-bottom: 1px solid lightgrey;
    }
    .scrollableArea {
      padding-left: 30px;
      max-height: 400px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        @include themify($themes){
          background: themed(scrollbar-color);
        }
        border-radius: 5px;
      }
    }
    .lotteryWrapper {
      @include themify($themes) {
        color: themed(confirm-bet-lottery-text);
      }
    }
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .lotteryListWrapper {
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .row {
      .col-12 {
        padding: 0;
      }
      width: 100%;
      table, th, td {
        @include themify($themes) {
          border: 1px solid themed(confirm-bet-table-border-color);
        }
      }
      .topButtonRow {
        flex: 1;
        display: inline-flex;
        justify-content: space-evenly;
      }
      .orangeOutlined {
        border-radius: 5px;
        @include themify($themes) {
          border: 1px solid themed(customise-changlong-modal-orange);
        }
        .orangeBgwhiteText{
          display: inline-flex;
          align-items: center;
          @include themify($themes) {
            background-color: themed(customise-changlong-modal-orange);
          }
          color: $white;
        }
        .orangeText {
          @include themify($themes) {
            color: themed(customise-changlong-modal-orange);
          }
        }
        .slider {
          .rc-slider-handle,.rc-slider-dot-active{
            @include themify($themes) {
              border-color: themed(customise-changlong-modal-orange);
            }
          }
          .rc-slider-track{
            @include themify($themes) {
              background-color: themed(customise-changlong-modal-orange);
            }
          }
        }
      }
    }
    .button {
      width: 8em;
      height: 32px;
      cursor: pointer;
      display:inline-flex;
      justify-content: center;
      align-items: center;
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
        color: themed(theme-primary-color);
      }
      border-radius: 5px;
      &.selected{
        @include themify($themes) {
          background-color: themed(theme-primary-color);
          color: $white;
        }
      }
    }
    .templateRow {
      .optionItemWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        .optionWrapper {
          @include themify($themes){
            border: 1px solid themed(checkbox-option-border-color);
          }
          display: flex;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          .active {
            @include themify($themes){
              fill: themed(theme-primary-color);
              height: 12px;
              width: 12px;
            }
            svg {
              path {
                @include themify($themes){
                  fill: themed(theme-primary-color);
                }
              }
            }
          }
          .inactive {
            @include themify($themes){
              fill: $white;
            }
          }
          &.active {
            @include themify($themes){
              border: 1px solid themed(theme-primary-color);
            }
          }
        }
      }
      .templateCode {
        @include themify($themes){
          color: themed(customise-changlong-modal-template-code-color);
        }
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
    .button {
      cursor: pointer;
      display:inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
        color: $white;
      }
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: .25rem;
      width: 120px;
      height: 36px;
    }
  }
}