@import "../../../scss/web_base.scss";

.quickBetPanelWrapper {
  -webkit-box-shadow: 0px 0px 16px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 16px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 16px -5px rgba(0,0,0,0.75);
  padding: 0;
  background-color: $white;
  .quickBetPanelPanelHeader {
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes){
      background-color: themed(theme-primary-color);
    }
    color: $white;
    font-size: 16px;
    .quickSelectionWrapper {
      display: flex;
      justify-content: center;
      border-right: 0.5px solid $white;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      cursor: pointer;
    }
    .quickBetWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .activeType {
      opacity: 1;
    }
    .inactiveType {
      opacity: 0.5;
    }
  }
  .quickBetPanelContent{
    .quickSelectionContent{
      width:  -webkit-fill-available;
      margin: 8px;
      height: 340px;
      overflow: auto;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  
      resize: none;
      border-radius: 3px;
      @include themify($themes){
        border: 1px solid themed(quick-bet-border-color);
      }
    }
    .quickInputRow{
      @include themify($themes){
        border-left: 1px solid themed(quick-bet-border-color);
        border-right: 1px solid themed(quick-bet-border-color);
        border-bottom: 1px solid themed(quick-bet-border-color);
        color: themed(quick-bet-input-label)
      }
      padding: 6px 0;
      text-align: center;
      font-size: 12px;
      .inputBox{
        margin-left: 10px;
        width: 82px;
        height: 20px;
        @include themify($themes){
          border: 1px solid themed(quick-bet-border-color);
        }
        outline: none;
        padding-left: 5px;
        font-size: 12px !important;
      }
    }
    .quickBetBtnRow{
      padding: 4px 0;
      @include themify($themes){
        border-left: 1px solid themed(quick-bet-border-color);
        border-right: 1px solid themed(quick-bet-border-color);
      }
      .quickBetBtn{
        width: 65px;
        height: 24px;
        line-height: 12px;
        border-radius: 5px;
        @include themify($themes){
          background-color: themed(quick-bet-btn);
        }
        color: $white;
        font-size: 12px;
        margin: 0 8px;
      }
    }
    td{
      padding: 0px !important;
      cursor: pointer;
      @include themify($themes){
        border: 1px solid themed(table-border-color) !important; 
      }
    }
    .disabledCell{
      @include themify($themes){
        background-color: themed(quick-bet-disabled);
      }
    }
    .selectedCell{
      @include themify($themes){
        background-color: themed(quick-bet-selected);
      }
    }
    .redText{
      @include themify($themes){
        color: themed(result-red-text)
      }
    }
    .blueText{
      @include themify($themes){
        color: themed(result-blue-text)
      }
    }
    .greenText{
      @include themify($themes){
        color: themed(result-green-text)
      }
    }
  }
}
