@import "../../../scss/web_base.scss";

.probabilityResultWrapper {
  @include themify($themes){
    font-family: themed(font-family);
  }
  .selectionWrapper {
    height: 40px;
    width: 90px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $white;
    @include themify($themes){
      background-color: themed(theme-primary-color);
    }
    .el-icon-caret-top {
      color: $white;
      font-size: 8px;
      right: -4px;
    }
    input {
      color: $white;
      border: 0;
      @include themify($themes){
        background-color: themed(theme-primary-color);
      }
    }
  }
  .resultWrapper {
    margin-left: 10px;
    border-radius: 5px;
    height: max-content;
    width: calc(100% - 100px);
    @include themify($themes){
      border: 1px solid themed(table-border-color);
    }
    .navWrapper {
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-left: 15px;
      @include themify($themes) {
        background-color: themed(theme-third-color);
      }
      .dropdownItem {
        font-size: 13px;
        @include themify($themes) {
          color: themed(probability-result-value-label);
        }
      }
    }
    table {
      height: 50%;
      width: 100%;
      tr {
        display: flex;
        flex-direction: row-reverse;
        td {
          width: 4%;
          text-align: center;
          @include themify($themes){
            border-top: 1px solid themed(table-border-color);
            border-left: 1px solid themed(table-border-color);
          }
          &:last-child {
            border-left: 0;
          }
          .value {
            font-size: 14px;
          }
        }
      }
    }
  }
  .sideBetResultWrapper {
    margin-left: 100px;
    border-radius: 5px;
    height: max-content;
    width: calc(100% - 100px);
    @include themify($themes){
      border: 1px solid themed(table-border-color);
    }
    .navWrapper {
      height: 40px;
      align-items: center;
      display: flex;
      @include themify($themes) {
        background-color: themed(theme-third-color);
      }
      .sideBetItem {
        cursor: pointer;
        font-size: 13px;
        margin: 0 15px;
        padding-bottom: 3px;
        @include themify($themes) {
          color: themed(probability-result-value-label) 
        }
        &.active {
          @include themify($themes) {
            border-bottom: 3px solid themed(theme-primary-color);
          }
        }
      }
    }
    table {
      height: 50%;
      width: 100%;
      tr {
        display: flex;
        flex-direction: row-reverse;
        td {
          width: 4%;
          text-align: center;
          @include themify($themes){
            border-top: 1px solid themed(table-border-color);
            border-left: 1px solid themed(table-border-color);
          }
          &:last-child {
            border-left: 0;
          }
          .value {
            font-size: 14px;
          }
        }
      }
    }
  }
}