@import '../../../scss/base';

.modalWarpper {
  height: 100%;
  .modal-header {
    justify-content: center;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    h5 {
      font-size: 1.2em;
      font-weight: bold;
    }
  }
  .modal-body {
    padding: 0;
    flex-direction: column;
    overflow: scroll;
    max-height: 50vh;
    .modalBodyWrapper{
      overflow-y: scroll;
    }
    .row {
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      margin: 0;
      border-bottom: 1px solid $border;
      font-size: 15px;
      width: 279px;
      .radioButtonItem {
        svg {
          margin-top: 5px;
          font-size: 1.3em;
          @include themify($themes) {
            fill: themed(theme-primary-color);
            stroke: themed(theme-primary-color);
          } 
        }
      }
    }
    .disableClass {
      background-color:rgb(240, 240, 240);
      color:#7e7e7e;
    }
  }
  .modalFooter{
    .button{
      width: 100px;
      margin: 0 10px 0 10px;
      @include themify($themes) {
        border: 0px;
        background-color: themed(theme-primary-color);
        color: $white;
      }
    }
  }
}